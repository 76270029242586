<template>
  <div class="page-container">
    <div align="center" class="container">
      <b-row align-v="center" class="row pb-0 pt-4">
        <b-col class="col-md-auto d-none d-lg-block">
          <!-- Todo make this back button a seperate component -->
          <div class="p-2" @click="back">
            <font-awesome-icon icon="arrow-left" />
          </div>
        </b-col>
        <div class="col-xl text-center col-lg-4">
          <b-container>
            <b-row align-v="center">
              <h1 class="title-1 mb-1">{{ sensorId }}</h1>
            </b-row>
            <b-row align="center">
              <h1 class="title-2 mb-1">{{ roomName }}</h1>
            </b-row>
          </b-container>
        </div>
        <b-col class="col-md-auto" align-self-end>
          <b-form-group
            :label="$t('from')"
            label-for="from"
            content-cols="2"
            label-align="top"
            label-size="sm"
            align="left"
          >
            <datetime
              id="from"
              v-model="fromDate"
              input-class="form-control form-control-sm"
            ></datetime>
          </b-form-group>
        </b-col>
        <b-col class="col-md-auto" align-self-end>
          <b-form-group
            :label="$t('untilAndIncluding')"
            label-for="to"
            content-cols="2"
            label-align="top"
            label-size="sm"
            align="left"
          >
            <datetime
              id="to"
              v-model="toDate"
              title="To"
              input-class="form-control form-control-sm"
            ></datetime>
          </b-form-group>
        </b-col>
      </b-row>

      <b-overlay :show="busy" rounded="sm">
        <div v-if="co2s.length">
          <h5>{{ $t('co2') }} ({{ $t('ppm') }})</h5>
          <apexchart
            id="co2Chart"
            type="area"
            :height="chartheight"
            :options="chartDefinition.options"
            :series="co2s"
          ></apexchart>
        </div>
        <div v-if="temperatures.length">
          <h5>{{ $t('temperature') }} (℃)</h5>
          <apexchart
            id="temperatureChart"
            type="area"
            :height="chartheight"
            :options="chartDefinition.options"
            :series="temperatures"
          ></apexchart>
        </div>

        <div v-if="humidities.length">
          <h5>{{ $t('humidity') }} (%)</h5>
          <apexchart
            id="humidityChart"
            type="area"
            :height="chartheight"
            :options="chartDefinition.options"
            :series="humidities"
          ></apexchart>
        </div>
        <div v-if="sounds.length">
          <h5>{{ $t('sound') }} (dBm)</h5>
          <apexchart
            id="soundChart"
            type="area"
            :height="chartheight"
            :options="chartDefinition.options"
            :series="sounds"
          ></apexchart>
        </div>
        <div v-if="motions.length">
          <h5>{{ $t('motion') }}</h5>
          <apexchart
            id="motionChart"
            type="area"
            :height="chartheight"
            :options="chartDefinition.options"
            :series="motions"
          ></apexchart>
        </div>
        <div v-if="lights.length">
          <h5>{{ $t('light') }} (Lux)</h5>
          <apexchart
            id="lightChart"
            type="area"
            :height="chartheight"
            :options="chartDefinition.options"
            :series="lights"
          ></apexchart>
        </div>
        <div v-if="occupancies.length">
          <h5>{{ $t('occupancy') }}</h5>
          <apexchart
            id="occupancyChart"
            type="area"
            :height="chartheight"
            :options="chartDefinition.options"
            :series="occupancies"
          ></apexchart>
        </div>
        <div v-if="counters.length">
          <h5>{{ $t('counters') }}</h5>
          <apexchart
            id="countersChart"
            type="area"
            :height="chartheight"
            :options="chartDefinition.options"
            :series="counters"
          ></apexchart>
        </div>
        <div v-if="sensorHistory.history.length === 0" class="mt-5">
          <h4>{{ $t('noSensorData') }}</h4>
          {{ $t('pleaseChangeDates') }}
        </div>
        <b-button
          v-else
          class="mt-3"
          @click="csvExport(sensorHistory.history, `sensorHistory`)"
          >{{ $t('export') }}</b-button
        >
      </b-overlay>
    </div>
  </div>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import sensorMixin from '@/mixins/sensorMixins'
import { Datetime } from 'vue-datetime'
import moment from 'moment-timezone'
import VueApexCharts from 'vue-apexcharts'
import store from '@/store'

export default {
  name: 'SensorHistory',
  components: {
    datetime: Datetime,
    apexchart: VueApexCharts
  },
  mixins: [sensorMixin],
  data() {
    return {
      busy: false,
      sensorId: 'unknown',
      roomName: 'unknown',
      chartheight: '300px',
      fromDate: moment()
        .startOf('week')
        .add(1, 'days')
        .toISOString(),
      toDate: moment()
        .endOf('week')
        .toISOString(),
      chartDefinition: {
        options: {
          chart: {
            id: 'sensor-history',
            type: 'line',
            animations: {
              initialAnimation: {
                enabled: false
              }
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true
              }
            }
          },
          tooltip: {
            x: { format: 'dd-MMM-yyyy  HH:mm' }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'dd-MMM'
            }
          }
        },
        series: [
          {
            name: 'series-1',
            data: [[30], [40], [45], [50], [49], [60], [70], [91]]
          }
        ]
      },
      temperatures: [],
      co2s: [],
      humidities: [],
      motions: [],
      sounds: [],
      lights: [],
      counters: [],
      occupancies: [],
      sensorHistory: { history: [] }
    }
  },
  watch: {
    fromDate() {
      this.getSensorHistory()
    },
    toDate() {
      this.getSensorHistory()
    }
  },
  mounted() {
    if (this.$route.params.sensorId) {
      this.sensorId = this.$route.params.sensorId
    } else {
      const sensorHistoryParams = this.$store.getters[
        'common/getSensorHistoryParams'
      ]
      this.sensorId = sensorHistoryParams.sensorId
      if (sensorHistoryParams.fromDate)
        this.fromDate = sensorHistoryParams.fromDate
      if (sensorHistoryParams.toDate)
        this.toDate = sensorHistoryParams.toDate
    }
    this.getSensorHistory()
  },
  methods: {
    home(event) {
      window.location.href = '/workplace'
    },
    back(event) {
      window.history.go(-1)
    },
    async getSensorHistory() {
      if (this.busy) {
        return
      }
      this.busy = true
      try {
        // Fetch room name of sensor
        this.sensorData = await OkkuApi.getSensor(this.sensorId)

        // Check is sensor has been fetched
        if (this.sensorData != null) {
          if (this.isAssignedToDesk(this.sensorData)) {
            this.roomName = this.getSensorDeskName(this.sensorData)
          } else {
            this.roomName = this.getSensorRoomName(this.sensorData)
          }
        }

        const toAndIncludingDate = moment(this.toDate).endOf('day')
        const sensorHistory = await OkkuApi.getSensorHistory(
          this.fromDate,
          toAndIncludingDate.toISOString(),
          this.sensorId
        )

        this.sensorHistory = sensorHistory
        console.log(sensorHistory.lenth)

        const co2s = []
        const temperatures = []
        const humidities = []
        const motions = []
        const occupancies = []
        const sounds = []
        const lights = []
        const counters = []

        sensorHistory.history.forEach(record => {
          const datetime = moment(record.datetime).valueOf()
          if (record.co2) co2s.push([datetime, record.co2])
          if (record.temperature)
            temperatures.push([datetime, record.temperature])
          if (record.humidity)
            humidities.push([datetime, record.humidity])
          if (record.occupancy)
            occupancies.push([datetime, record.occupancy])
          if (record.soundAvg)
            sounds.push([datetime, record.soundAvg])
          if (record.light) lights.push([datetime, record.light])
          if (record.counter)
            counters.push([datetime, record.counter])
          if (record.motion) motions.push([datetime, record.motion])
        })

        const labelDetails = sensorHistory.labels[0]
        const seriesName = `${labelDetails.object_type} ${labelDetails.object_name} ${labelDetails.sensor_id}`
        console.log(co2s.length)
        if (co2s.length > 0)
          this.co2s = [
            {
              name: seriesName,
              data: co2s
            }
          ]
        if (temperatures.length > 0)
          this.temperatures = [
            {
              name: seriesName,
              data: temperatures
            }
          ]
        if (humidities.length > 0)
          this.humidities = [
            {
              name: seriesName,
              data: humidities
            }
          ]
        if (motions.length > 0)
          this.motions = [
            {
              name: seriesName,
              data: motions
            }
          ]
        if (sounds.length > 0)
          this.sounds = [
            {
              name: seriesName,
              data: sounds
            }
          ]
        if (counters.length > 0)
          this.counters = [
            {
              name: seriesName,
              data: counters
            }
          ]
        if (lights.length > 0)
          this.lights = [
            {
              name: seriesName,
              data: lights
            }
          ]
        if (occupancies.length > 0)
          if (occupancies.length > 0)
            this.occupancies = [
              {
                name: seriesName,
                data: occupancies
              }
            ]
      } catch (err) {
        console.error(err)
      }

      this.busy = false
    },
    csvExport(arrData, fileName) {
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.keys(arrData[0]).join(';'),
        ...arrData.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute(
        'download',
        `${store.state.common.$organisation}-${fileName}.csv`
      )
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
#chart {
  margin: 35px auto;
  opacity: 0.9;
}

h5 {
  margin-top: 40px;
}
</style>
